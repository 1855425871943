






















































































import Vue from "vue";
import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import AppModule from "@/store/modules/AppModule";
import TheNavigationDrawer from "@/components/TheNavigationDrawer.vue";

export interface SocialNetworkItem {
  ref: string;
  icon: string;
  name: string;
}

@Component({
  components: { TheNavigationDrawer }
})
export default class App extends Vue {

  drawer = false;
  readonly socialNetworks: SocialNetworkItem[] = [
    {
      ref: "",
      name: "Facebook",
      icon: "mdi-facebook",
    },
    {
      ref: "",
      name: "Instagram",
      icon: "mdi-instagram",
    },
    {
      ref: "",
      name: "twitter",
      icon: "mdi-twitter",
    },
    {
      ref: "",
      name: "whatsapp",
      icon: "mdi-whatsapp",
    }
  ];
  get theme(): string {
    return this.$vuetify.theme.dark ? "dark" : "light";
  }

  showDrawer(): void {
    this.drawer = !this.drawer;
  }
}
