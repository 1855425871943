import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#31556B",
        secondary: "#C48649", // #FFCDD2
        accent: "#AD6952", // #3F51B5
        background: "#F7F0EB",
      },
    },
  },
});
