








































import Component from "vue-class-component";
import Vue from "vue";

import { Model, PropSync } from "vue-property-decorator";

export interface TheNavigationDrawerItem{
  icon: string;
  title: string;
}

@Component
export default class TheNavigationDrawer extends Vue {
  readonly items: TheNavigationDrawerItem[] = [
    { title: 'Início', icon: 'mdi-view-dashboard' },
    { title: 'Sobre', icon: 'mdi-help-box' },
    { title: 'Contatos', icon: 'mdi-email' },
  ];

  @PropSync("model", { type: Boolean })
  modelValue!: boolean;
}
